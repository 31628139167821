import {
  Autocomplete,
  Box,
  CircularProgress,
  Fade,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useMemo,
} from "react";
import { processSortFn, string2Date } from "../../../util/utils";
import {
  ArrowBackRounded,
  Cancel,
  CheckCircle,
  FlightTakeoff,
  ThreeDRotation,
} from "@mui/icons-material";
import { HvPylon } from "../../../types/grid";
import {
  ApiMetadataResponse,
  ApiSolutionsResponse,
  Features,
  MediaDataType,
  Metadata,
  PROCESS_PROCESSING_LEVEL,
  Process,
  Solution,
} from "../../../types/media";
import { useStyles } from "../styles";
import { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DateRange } from "../MDPViewer";

interface ResultsProps {
  isPylonSearch: boolean;
  selectedPylon: HvPylon | null;
  respData: ApiMetadataResponse;
  setSelectedId: (id: string) => void;
  selectedProcesses: Process[];
  clickedProcess: Process | null;
  selectedId: string | null;
  setClickedProcess: (process: Process | null) => void;
  setHoverProcessId: (id: string | null) => void;
  setHoverId: (id: string | null) => void;
  resultLoading: boolean;
  fileProcessingLevels: string[];
  selectedFileProcessingLevel: string | null;
  setSelectedFileProcessingLevel: (level: string | null) => void;
  solutions: ApiSolutionsResponse;
  selectedSolution: Solution | null;
  setSelectedSolution: (solution: Solution | null) => void;
  processProcessingLevels: string[];
  selectedProcessProcessingLevel: string | null;
  setSelectedProcessProcessingLevel: (level: string | null) => void;
  dateRange: DateRange<Dayjs>;
  setDateRange: Dispatch<SetStateAction<DateRange<Dayjs>>>;
  resetDateRange: () => void;
}

export const getDummyImage = (fileName, size = "thumb") => {
  if (`${fileName}`.includes("1")) {
    return `DJI_0008${size == "thumb" ? "_thumb" : ""}.jpg`;
  }
  if (`${fileName}`.includes("2")) {
    return `DJI_0034${size == "thumb" ? "_thumb" : ""}.jpg`;
  }
  return `DJI_0032${size == "thumb" ? "_thumb" : ""}.jpg`;
};

export const MetadataIcon = ({
  type,
  props = {},
}: {
  type: MediaDataType | string;
  props?: Record<string, {}>;
}) => {
  switch (type) {
    case MediaDataType.PointCloud:
      return <ThreeDRotation color="secondary" {...props} />;
    case MediaDataType.DroneImage:
      return <FlightTakeoff color="secondary" {...props} />;
    default:
      return <FlightTakeoff color="secondary" {...props} />;
  }
};

export const Results = ({
  isPylonSearch,
  selectedPylon,
  respData,
  setSelectedId,
  selectedId,
  setHoverId,
  setHoverProcessId,
  selectedProcesses,
  clickedProcess,
  setClickedProcess,
  resultLoading,
  fileProcessingLevels,
  processProcessingLevels,
  selectedFileProcessingLevel,
  selectedProcessProcessingLevel,
  setSelectedFileProcessingLevel,
  setSelectedProcessProcessingLevel,
  solutions,
  selectedSolution,
  setSelectedSolution,
  dateRange,
  setDateRange,
}: ResultsProps) => {
  const removeClickedProcess = () => {
    setClickedProcess(null);
    setSelectedId("");
  };

  useEffect(() => {
    return () => {
      removeClickedProcess();
    };
  }, []);

  return (
    <Fade in>
      <Box
        height="100%"
        overflow="hidden"
        display="flex"
        flexDirection="column"
        data-testid="results"
      >
        <Box display="flex" px={2} mt={0.5} gap={1}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{ start: "Check-in", end: "Check-out" }}
          >
            <DatePicker
              label="Start date"
              format="YYYY/MM/DD"
              value={dateRange[0]}
              onChange={(newValue) => {
                setDateRange((range) => [newValue, range[1]]);
              }}
            />
            <DatePicker
              label="End date"
              format="YYYY/MM/DD"
              value={dateRange[1]}
              onChange={(newValue) => {
                setDateRange((range) => [range[0], newValue]);
              }}
            />
          </LocalizationProvider>
        </Box>
        <Box display="flex" gap={2} px={2}>
          {!clickedProcess ? (
            // process processing level
            <Autocomplete
              fullWidth
              id="processProcessingLevelSelector"
              aria-label="Search process processing level selector"
              options={processProcessingLevels}
              value={selectedProcessProcessingLevel}
              onChange={(
                _event: ChangeEvent<{}>,
                selectedValue: string | null,
              ) => {
                setSelectedProcessProcessingLevel(selectedValue);
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label="Processing Level"
                    variant="standard"
                    autoComplete="off"
                  />
                );
              }}
            />
          ) : (
            // metadata processing level
            <Autocomplete
              fullWidth
              id="metadataProcessingLevelSelector"
              aria-label="Search metadata processing level selector"
              options={fileProcessingLevels}
              value={selectedFileProcessingLevel}
              onChange={(
                _event: ChangeEvent<{}>,
                selectedValue: string | null,
              ) => {
                setSelectedFileProcessingLevel(selectedValue);
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label="Processing Level"
                    variant="standard"
                    autoComplete="off"
                  />
                );
              }}
            />
          )}
          <Autocomplete
            fullWidth
            id="solutionSelector"
            aria-label="Search solution selector"
            options={solutions?.results || []}
            getOptionLabel={(option: Solution) => option.name}
            getOptionKey={(option: Solution) => option?.id || "n/a"}
            value={selectedSolution as Solution}
            onChange={(
              _event: SyntheticEvent<Element, Event>,
              value: Solution | null,
            ) => {
              setSelectedSolution(value);
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label="Solution"
                  variant="standard"
                  autoComplete="off"
                />
              );
            }}
          />
        </Box>

        {/* loading */}
        {resultLoading && (
          <Box
            display="flex"
            justifyContent="center"
            height={100}
            alignItems="center"
          >
            <CircularProgress size={24} />
          </Box>
        )}
        {/* metadata search type result */}
        {!resultLoading && (
          <Box
            height="100%"
            overflow="hidden"
            display="flex"
            flexDirection="column"
          >
            <Box
              display="flex"
              p={2}
              alignItems="center"
              minHeight="18px"
              flexShrink={0}
            >
              {clickedProcess ? (
                <>
                  <IconButton
                    edge="start"
                    onClick={removeClickedProcess}
                    size="small"
                    aria-label="go back to process list"
                    sx={{ padding: 0, mr: 1 }}
                  >
                    <ArrowBackRounded fontSize="small" />
                  </IconButton>
                  <Typography variant="body1">
                    Drone Images at {clickedProcess.name}
                  </Typography>
                </>
              ) : (
                <Typography variant="body1">
                  Dataset Results{" "}
                  {isPylonSearch &&
                    `for HV-Pylon ${selectedPylon?.attributes?.name}`}
                </Typography>
              )}
            </Box>
            {clickedProcess ? (
              <>
                <MetadataList
                  respData={respData}
                  setSelectedId={setSelectedId}
                  selectedId={selectedId}
                  setHoverId={setHoverId}
                  filterFn={(item) => item.processId === clickedProcess.id}
                />
              </>
            ) : (
              <ProcessList
                setClickedProcess={setClickedProcess}
                selectedProcesses={selectedProcesses}
                setHoverProcessId={setHoverProcessId}
              />
            )}
          </Box>
        )}
        {/* metadata search type result 
        {!resultLoading && !isPylonSearch && (
          <Box
            height="100%"
            overflow="hidden"
            display="flex"
            flexDirection="column"
          >
            <Typography variant="h6" p={2}>
              Dataset Result
            </Typography>
            <MetadataList
              respData={respData}
              setSelectedId={setSelectedId}
              setHoverId={setHoverId}
            />
          </Box>
        )}
        */}
      </Box>
    </Fade>
  );
};

type ProcessListType = Pick<
  ResultsProps,
  "selectedProcesses" | "setClickedProcess" | "setHoverProcessId"
>;

const ProcessList = ({
  selectedProcesses,
  setClickedProcess,
  setHoverProcessId,
}: ProcessListType) => {
  const { classes } = useStyles();
  useEffect(() => {
    return () => {
      setHoverProcessId(null);
    };
  }, []);
  return (
    <Box overflow="scroll">
      {selectedProcesses.length > 0 ? null : (
        <Typography variant="body1" px={2} color="error">
          No Result for selected criteria.
        </Typography>
      )}
      {selectedProcesses.sort(processSortFn).map((item) => (
        <Box
          key={item.id}
          className={classes.searchItem}
          p={2}
          onClick={() => {
            setClickedProcess(item);
          }}
          onMouseEnter={() => {
            setHoverProcessId(item.id as string);
          }}
          onMouseLeave={() => {
            setHoverProcessId(null);
          }}
        >
          <Box
            display="flex"
            gap={2}
            overflow="hidden"
            justifyContent="space-between"
            width={1}
          >
            <Box
              flexShrink={1}
              display="flex"
              flexDirection="column"
              overflow="hidden"
            >
              <Typography
                display="flex"
                alignItems="center"
                gap={1}
                fontSize={16}
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {item?.name}
              </Typography>
              <Typography
                variant="body1"
                color="gray"
                textOverflow="ellipsis"
                overflow="hidden"
                fontSize={13}
              >
                created: {string2Date(item.creationDate)}
              </Typography>
              <Typography
                variant="body1"
                color="gray"
                textOverflow="ellipsis"
                overflow="hidden"
                fontSize={13}
              >
                {item.id}
              </Typography>
            </Box>
            <Box flexShrink={0}>
              {item.processingLevel === PROCESS_PROCESSING_LEVEL.PROCESSED ? (
                <CheckCircle color="secondary" />
              ) : (
                <Cancel color="error" />
              )}
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

type MetadataListComponentType = Pick<
  ResultsProps,
  "respData" | "setSelectedId" | "setHoverId" | "selectedId"
> & {
  filterFn?: (item: Metadata) => boolean;
};

const MetadataList = ({
  respData,
  setSelectedId,
  selectedId,
  setHoverId,
  filterFn = () => true,
}: MetadataListComponentType) => {
  const { classes } = useStyles();

  const data = useMemo(() => {
    return (respData as ApiMetadataResponse)?.results?.filter(filterFn) || [];
  }, [respData, filterFn]);

  useEffect(() => {
    return () => {
      setHoverId(null);
    };
  }, []);

  return (
    <Box overflow="scroll">
      {data.length > 0 ? null : (
        <Typography variant="body1" px={2} color="error">
          No Result for selected criteria.
        </Typography>
      )}
      {data.map((item) => {
        let hasCoordinates = true;
        const geoJson = item?.geoJson as unknown as Features;
        if (
          !geoJson ||
          !Array.isArray(geoJson.features) ||
          !geoJson.features[0].geometry
        ) {
          hasCoordinates = false;
        }

        return (
          <Box
            key={item.id}
            className={classes.searchItem}
            display="flex"
            justifyContent="space-between"
            bgcolor={item.id === selectedId ? "#dddddd" : ""}
            gap="1rem"
            p={2}
            onClick={() => {
              setSelectedId(item.id as string);
            }}
            onMouseEnter={() => {
              setHoverId(item.id as string);
            }}
            onMouseLeave={() => {
              setHoverId(null);
            }}
          >
            <Box
              display="flex"
              gap={2}
              overflow="hidden"
              justifyContent="space-between"
              width={1}
            >
              <Box
                flexShrink={1}
                display="flex"
                flexDirection="column"
                overflow="hidden"
              >
                <Typography
                  display="flex"
                  alignItems="center"
                  gap={1}
                  fontSize={16}
                  textOverflow="ellipsis"
                  overflow="hidden"
                >
                  <MetadataIcon type={item.mediadataType} />
                  {item?.fileName ? item.fileName : "N/A"}
                </Typography>
                <Typography
                  variant="body1"
                  color="gray"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  fontSize={13}
                >
                  created: {string2Date(item.fileCreationDate)}
                </Typography>
                <Typography
                  variant="body1"
                  color="gray"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  fontSize={13}
                >
                  {item.id}
                </Typography>
                {/* <Typography
                variant="body1"
                color="gray"
                textOverflow="ellipsis"
                overflow="hidden"
                fontSize={13}
              >
                {item.processId}
              </Typography> */}
                {!hasCoordinates && (
                  <Typography variant="caption" fontSize={14} color="red">
                    Geolocation coordinates are missing.
                  </Typography>
                )}
              </Box>
              {item.mediadataType === MediaDataType.DroneImage && (
                <Box flexShrink={0}>
                  <Box
                    width={100}
                    height={100}
                    flexShrink={0}
                    overflow="hidden"
                    borderRadius={2}
                  >
                    <img
                      src={getDummyImage(`${item.fileName}`)}
                      alt="pylon-image"
                      style={{
                        objectFit: "cover",
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
