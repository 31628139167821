import React, { useEffect, useMemo, useState } from "react";
import { BillboardGraphics, Entity, ModelGraphics } from "resium";
import * as Cesium from "cesium";
import { ApiHvPylonResponse, HvPylon } from "../../../types/grid";
import { Process } from "../../../types/media";
import { HeightAwareEntity } from "./HeightAwareEntity";

interface PylonGeoJsonComponent {
  is3D?: boolean;
  selectedPylon: HvPylon | null;
  pylonList: ApiHvPylonResponse;
  selectedProcesses: Process[];
  setSelectedPylon: (pylon: HvPylon) => void;
  heightFunction: (position: Cesium.Cartesian3) => Promise<number | undefined>;
}

export const PylonGeoJsonComponent = ({
  is3D = false,
  setSelectedPylon,
  selectedPylon,
  pylonList,
  selectedProcesses,
  heightFunction,
}: PylonGeoJsonComponent) => {
  const [data, setData] = useState<HvPylon[]>([]);

  useEffect(() => {
    if (selectedPylon) {
      setData([selectedPylon]);
      return;
    }

    if (pylonList?.data) {
      setData(pylonList.data);
    } else {
      setData([]);
    }
  }, [pylonList, selectedPylon]);

  const pylons = useMemo(() => {
    return data.map((marker) => {
      const pylon = marker.attributes;
      const pointCoords = Cesium.Cartesian3.fromDegreesArray(
        pylon?.geometry?.coordinates as number[],
      );
      const calculatedPylonScale = pylon?.height === 9755 ? 0.08 : 0.065;
      const image = generateCounterImage(selectedProcesses.length) as string;
      return (
        <HeightAwareEntity
          key={`${marker.id}`}
          position={pointCoords[0]}
          coordinates={pylon?.geometry?.coordinates as number[]}
          heightFunction={heightFunction}
          offset={is3D ? (pylon?.height || 1) / 100 + 20 : 0}
        >
          {({ position }) => (
            <Entity
              id={marker.id}
              key={marker.id}
              position={position}
              name={pylon?.name || ""}
              properties={{ type: is3D ? "pylon_3d" : "pylon_2d" }}
              onClick={() => {
                setSelectedPylon(marker);
              }}
              label={
                is3D
                  ? {
                      fillColor: Cesium.Color.BLACK,
                      // fillColor: {
                      //   rgba: [255, 255, 255, 255],
                      // },
                      // eyeOffset: new Cesium.Cartesian3(0.0, 8000000.0, 0.0),
                      font: "11pt Lucida Console",
                      // style: "FILL",
                      // scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1.5, 8.0e6, 0.0),
                      text: `${pylon?.name}`,
                      showBackground: false,
                      backgroundColor: Cesium.Color.WHITE,
                      pixelOffset: new Cesium.Cartesian2(0, -1),
                      heightReference: Cesium.HeightReference.NONE,
                      disableDepthTestDistance: Number.POSITIVE_INFINITY,
                    }
                  : {
                      fillColor: Cesium.Color.BLACK,
                      // fillColor: {
                      //   rgba: [255, 255, 255, 255],
                      // },
                      font: "12pt Lucida Console",
                      // horizontalOrigin: "LEFT",
                      pixelOffset: new Cesium.Cartesian2(0, -35),
                      // style: "FILL",
                      text: pylon?.name as string,
                      showBackground: false,
                      backgroundColor: Cesium.Color.WHITE,
                      heightReference: Cesium.HeightReference.NONE,
                      disableDepthTestDistance: Number.POSITIVE_INFINITY,
                    }
              }
            >
              {is3D ? (
                <ModelGraphics
                  heightReference={Cesium.HeightReference.CLAMP_TO_TERRAIN}
                  uri="pylon.gltf"
                  nodeTransformations={{
                    pylon: new Cesium.TranslationRotationScale(
                      new Cesium.Cartesian3(-150, 0, 150),
                    ),
                  }}
                  scale={calculatedPylonScale}
                />
              ) : (
                <BillboardGraphics
                  image="pylon.svg"
                  scale={1}
                  disableDepthTestDistance={Number.POSITIVE_INFINITY}
                  heightReference={Cesium.HeightReference.NONE}
                />
              )}
            </Entity>
          )}
          {/* 
            <PointGraphics {...EntityGraphicsState.NORMAL_Red_Point_Graphics} />
            {selectedPylon && (
              <BillboardGraphics
                image={image}
                scale={1}
                color={new Cesium.Color(1.0, 1.0, 1.0, 0.8)}
                pixelOffset={new Cesium.Cartesian2(50, 20)}
              />
            )} 
          */}
          {/* height
            <Entity
              show={false}
              position={Cesium.Cartesian3.fromDegrees(
                pylon?.geometry?.coordinates[0] as number,
                pylon?.geometry?.coordinates[1] as number,
                (pylon?.height || 1) / 100,
              )}
            >
              <EllipsoidGraphics
                {...EntityGraphicsState.NORMAL_metadata_Ellipsoid_Graphics}
                heightReference={Cesium.HeightReference.RELATIVE_TO_TERRAIN}
              />
            </Entity>
          */}
        </HeightAwareEntity>
      );
    });
  }, [
    is3D,
    data,
    selectedProcesses,
    setSelectedPylon,
    selectedPylon,
    heightFunction,
  ]);

  if (data && data?.length <= 0) return null;
  return pylons;
};

const generateCounterImage = (counter: number) => {
  // Set canvas size
  const size = 64;

  // Create canvas element
  let canvas: HTMLCanvasElement | null = document.createElement("canvas");
  canvas.width = size;
  canvas.height = size;
  const context = canvas.getContext("2d");
  if (!context) return null;

  // Draw circle
  const centerX = canvas.width / 2;
  const centerY = canvas.height / 2;
  const radius = size / 2 - 10; // Adjust the radius as needed

  context.shadowColor = "#b9b9b9";
  context.shadowBlur = 5;

  // border
  context.beginPath();
  context.arc(centerX, centerY, radius + 2, 0, 2 * Math.PI);
  context.fillStyle = "#000"; // White circle
  context.fill();

  context.shadowBlur = 0;

  context.beginPath();
  context.arc(centerX, centerY, radius, 0, 2 * Math.PI);
  context.fillStyle = "#808080"; // White circle
  context.fill();

  // Draw counter text
  context.font = "bold 18px Lucida Console";
  context.fillStyle = "#000000"; // Black text color
  context.textAlign = "center";
  context.textBaseline = "middle";
  context.fillText(counter.toString(), centerX, centerY + 2);

  // Convert canvas to data URI
  const dataURI = canvas.toDataURL();

  // Clean up
  canvas = null;

  return dataURI;
};
