import React, { useEffect, useMemo, useState } from "react";
import {
  EllipsoidGraphics,
  Entity,
  PointGraphics,
  PolygonGraphics,
} from "resium";
import * as Cesium from "cesium";
import { EntityGraphicsState } from "../MDPViewer";
import {
  ApiMetadataResponse,
  Features,
  Metadata,
  Process,
  MediaDataType,
} from "../../../types/media";
import { HeightAwareEntity } from "./HeightAwareEntity";

const parse3DPosFromPolygonRaw = (location: string = "") => {
  const coordinates: string[] | null = location.match(/\(\(([^)]+)\)/);
  if (!(Array.isArray(coordinates) && coordinates.length > 1)) return [];
  const coordArray: number[] = coordinates[1]
    .split(" ")
    .map((item) => parseFloat(item));
  const chunks: number[][] = [];
  const chunkSize = 3; // long, lat, alt
  for (let i = 0; i < coordArray.length; i += chunkSize) {
    chunks.push(coordArray.slice(i, i + chunkSize));
  }
  return chunks;
};

const parse3DPosRaw = (location: string = "") => {
  const coordinates: string[] | null = location.match(/\(([^)]+)\)/);
  if (!(Array.isArray(coordinates) && coordinates.length > 1)) return;
  const coordArray: number[] = coordinates[1]
    .split(" ")
    .map((item) => parseFloat(item));
  return coordArray;
};

const parse3DPos = (location: string = "") => {
  const coordinates: string[] | null = location.match(/\(([^)]+)\)/);
  if (!(Array.isArray(coordinates) && coordinates.length > 1))
    return new Cesium.Cartesian3();
  const coordArray: number[] = coordinates[1]
    .split(" ")
    .map((item) => parseFloat(item));
  return Cesium.Cartesian3.fromDegrees(
    coordArray[0],
    coordArray[1],
    coordArray[2],
  );
};

interface CesiumGeoJsonCompProp {
  mapData: ApiMetadataResponse;
  is3D?: boolean;
  isPylonSearch?: boolean;
  clickedProcess?: Process | null;
  getPointCloudData: (id: string | undefined, type?: string) => any[];
  heightFunction: (position: Cesium.Cartesian3) => Promise<number | undefined>;
}

export const MetadataGeoJsonComponent = ({
  mapData,
  isPylonSearch = false,
  is3D = false,
  clickedProcess = null,
  getPointCloudData,
  heightFunction,
}: CesiumGeoJsonCompProp) => {
  const [data, setData] = useState<Metadata[]>([]);

  useEffect(() => {
    if (mapData.results) {
      setData(mapData.results);
    } else {
      setData([]);
    }
  }, [mapData]);

  const metadata = useMemo(() => {
    const filterFn = clickedProcess
      ? (item) => item.processId === clickedProcess.id
      : () => true;
    return data.filter(filterFn).map((marker) => {
      const geoJson: Features = marker?.geoJson as unknown as Features;
      if (!geoJson.features[0].geometry) return null;
      const metadata = marker as Metadata;

      if (metadata.mediadataType === MediaDataType.DroneImage) {
        const coordinates = geoJson.features[0].geometry
          .coordinates as number[];
        return (
          <HeightAwareEntity
            key={`${metadata.id}_`}
            position={parse3DPos(metadata.geom3D)}
            coordinates={coordinates}
            heightFunction={heightFunction}
          >
            {({ position }) => (
              <Entity
                key={metadata.id}
                id={metadata.id}
                position={position}
                name={metadata.fileName}
                properties={{ type: is3D ? "metadata_3d" : "metadata_2d" }}
              >
                {is3D ? (
                  <EllipsoidGraphics
                    {...EntityGraphicsState.NORMAL_metadata_Ellipsoid_Graphics}
                    heightReference={Cesium.HeightReference.RELATIVE_TO_TERRAIN}
                  />
                ) : (
                  <PointGraphics
                    {...EntityGraphicsState.NORMAL_metadata_Point_Graphics}
                    heightReference={Cesium.HeightReference.NONE}
                    disableDepthTestDistance={Number.POSITIVE_INFINITY}
                  />
                )}
              </Entity>
            )}
          </HeightAwareEntity>
        );
      } else if (metadata.mediadataType === MediaDataType.PointCloud) {
        const metadataRectCoord = Cesium.Cartesian3.fromDegreesArray(
          (geoJson.features[0].geometry.coordinates[0] as number[]).flat(),
        );
        const pointCloudDetails = getPointCloudData(metadata.id);
        let maxHeight = 0;
        if (pointCloudDetails.length > 0) {
          maxHeight = pointCloudDetails[0].pcMaxHeight;
        }
        const graphics = is3D
          ? EntityGraphicsState.NORMAL_metadata_Polygon_graphics
          : EntityGraphicsState.NORMAL_metadata_Polygon_graphics;

        return (
          <Entity
            key={metadata.id}
            id={metadata?.id}
            selected={false}
            properties={{
              type: is3D
                ? "metadata_point_cloud_3d"
                : "metadata_point_cloud_2d",
            }}
          >
            <PolygonGraphics
              hierarchy={metadataRectCoord}
              {...graphics}
              heightReference={Cesium.HeightReference.CLAMP_TO_TERRAIN}
              extrudedHeight={is3D ? maxHeight : 2}
            />
          </Entity>
        );
      }
    });
  }, [data, is3D, clickedProcess, getPointCloudData, heightFunction]);

  // do not render if there is no data
  const length = data?.length;
  if (length != undefined && length <= 0) return null;
  if (isPylonSearch && clickedProcess === null) return null;

  return <>{metadata}</>;
};
